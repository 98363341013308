import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "context/Auth";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "componentsPro/Grid/GridContainer.js";
import GridItem from "componentsPro/Grid/GridItem.js";

import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";

import headersStyle from "assetskit/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

const useStyles = makeStyles(headersStyle);

function Main() {
  const classes = useStyles();
  const { isAuthenticated, getRefUrl } = useAuth();
  //const youtube_id = "_qRmUQEqaTA";
  const youtube_id = "pb7sECcpezc";

  if (isAuthenticated()) {
    const refUrl = getRefUrl();
    if (refUrl !== "" && refUrl != "/p/main") return <Redirect to={refUrl} />;
    else return <Redirect to="/u/my-processes" />;
  }

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <h1 className={classes.title}>זירת המועמדים</h1>
          <p className={classes.desc}>
            המקום בו תוכלו להתעדכן באופן שוטף לגבי התקדמותכם במשרות שאליהן הופניתם, לעקוב הכי מקרוב אחר סטאטוס התקדמות
            המועמדים ששלחתם אלינו במסגרת מבצעי ״חבר מביא חבר״ ולצפות בנתוני הראיון האחרון שביצעתם עם לוגי (ולהתראיין
            במידה וטרם ביצעתם ראיון)
          </p>

          <Button
            component={Link}
            color="danger"
            size="lg"
            to="/p/login"
            rel=""
            style={{ fontSize: "1rem", marginLeft: "20px" }}
          >
            <Fingerprint />
            התחברות
          </Button>

          <Button component={Link} color="info" size="lg" to="/p/register" rel="" style={{ fontSize: "1rem" }}>
            <PersonAdd />
            הרשמה
          </Button>
        </GridItem>
        <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
          <div className={classes.video}>
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={`https://www.youtube.com/embed/${youtube_id}`}
              frameBorder="0"
              title="הכירו את לוגי! המגייס הדיגיטלי שימצא לכם עבודה בהייטק."
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Main;
