import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ContactMailIcon from "@material-ui/icons/ContactMail";

import { useAuth } from "context/Auth";
import { FiLogOut } from "react-icons/fi";

import ContactUsDialog from "components/Dialogs/ContactUsDialog.js";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const { handleLogout } = useAuth();
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);

  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { color, logo, image, logoText, routes } = props;

  const handleContactUs = (e) => {
    e.preventDefault();
    setOpenContactUsDialog(true);
  };

  var links = (
    <>
      <List component="nav" className={classes.list} id="sidebar-menu" role="navigation">
        {routes.map((prop, key) => {
          var activePro = " ";
          var listItemClasses;
          if (prop.path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true,
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(prop.layout + prop.path),
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          });
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem
                button={false}
                className={classes.itemLink + listItemClasses}
                onClick={() => (isMobile ? props.handleDrawerToggle() : "")}
              >
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })}
        <NavLink
          to="/contact-us"
          className={classes.item}
          onClick={(e) => {
            handleContactUs(e);
          }}
        >
          <ListItem button={false} className={classes.itemLink}>
            <ContactMailIcon
              className={classNames(classes.itemIcon, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            />
            <ListItemText
              primary="יצירת קשר"
              className={classNames(classes.itemText, {
                [classes.itemTextRTL]: props.rtlActive,
              })}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
        <NavLink
          to="/logout"
          className={classes.item}
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
        >
          <ListItem button={false} className={classes.itemLink}>
            <FiLogOut
              className={classNames(classes.itemIcon, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            />
            <ListItemText
              primary="התנתקות"
              className={classNames(classes.itemText, {
                [classes.itemTextRTL]: props.rtlActive,
              })}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      </List>
    </>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="דף הבית לוג און" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          //anchor={props.rtlActive ? "left" : "right"}
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{
                //backgroundImage: "url(" + image + ")",
                backgroundColor: "#000",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "contain",
                // backgroundPosition: "center bottom",
              }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{
                //backgroundImage: "url(" + image + ")",
                backgroundColor: "#000",
                //backgroundRepeat: "no-repeat",
                backgroundSize: "initial",
                backgroundPosition: "center bottom",
              }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <ContactUsDialog open={openContactUsDialog} setOpen={setOpenContactUsDialog} />
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
